.pageHeader {
	margin-top: 0px;
	height: 300px;
	width: 100%;
	background-color: black;
	
}
.headerwrapper {
	width: 100%;
	height: 100%;
	position: relative;
}


.pageHeader .headerimg {
	width: 100%;
	/* min-height: 500px; */
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	/* background-repeat: no-repeat; */
	/* background-attachment: fixed; */
	/* background-position: 20% 800%; */
	/* background-blend-mode: overlay; */
	/* animation: moveback 7s  linear infinite; */
	filter: blur(20px);
	position: absolute ;
}

.pageHeader .headercontent {
	position: absolute ;
	width: 100%;
}
.headingHeader {
	color: #f5f5f7;
	/* font-size: 3vw; */
    line-height: 1.05;
    font-weight: 600;
    letter-spacing: -.015em;
	font-family: Arial, Helvetica, sans-serif;
	text-align: center;
	margin-top: 80px;
}

.headingSubtitle {
	color: #f5f5f7;
	/* font-size: 1.3vw; */
    line-height: 1.05;
    font-weight: 100;
    letter-spacing: -.015em;
	font-family: Arial, Helvetica, sans-serif;
	text-align: center;
	margin-top: 30px;
	font-style: italic;
	padding-left: 10px;
	padding-right: 10px;
}

.headingSeperator {
	height: 1px;
	margin: auto;
	width: 30%;
	margin-top: 30px;
	background-color: #f5f5f7;
}

@keyframes moveback {
	0% {
		background-position: 20% 800%;
	}
	25% {
		background-position: 300% 400%;
	}
	50% {
		background-position: 20% 100%;
	}
	100% {
		background-position: 20% 800%;
	}
}
#gradient-canvas {
	width:100%;
	height:100%;
	--gradient-color-1: #cf01ff; 
	--gradient-color-2: #3c01fe; 
	--gradient-color-3: #1f29a0;  
	--gradient-color-4: #c602fd;
  }
		