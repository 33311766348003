.recognition .imagewrapper {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 1.5rem;
}

.recognition .imagewrapper .img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 1.5rem;
    margin: auto;
    display: block;
    padding: 0.1rem;
}