
body {
	padding: 0;
	margin: 0;
  }
  
  
  .container {
	width: 100%;
	height: 100vh;
	background: rgb(57, 49, 63);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  }
  
  .container > h1 { color: rgb(255, 234, 195) }
  
  
  
  
  .carousel {
	width: 700px;
	/* height: 300px; */
	/* background: rgba(47, 134, 75, .71); */
	direction: rtl;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	overflow: hidden;
  }
  
  .carousel.active {
	cursor: -webkit-grabbing;
	cursor: grabbing;
  }
  
  .cWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
  }
  
  .item {
	background: coral;
	display: flex;
	justify-content: center;
	align-items: center;
  }
  
  .item p {
	position: relative;
	top: -5px;
	font-size: 5rem;
	font-weight: bold;
	color: #fff;
	user-select: none;
  }
  
  .item:first-child { margin-right: 0px !important;}
  .item:last-child { margin-left: 0px !important;}