.Sell .back-tint2:before {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0,0,0,0.65);
	transition: all .3s linear;
}

.Sell .backimg {
	top: 0;
	left: 0;
	right: 0;
	height: calc(75% + 200px);
	background-image: url(https://assets-global.website-files.com/5ec85520c4dfff034b036be2/61e607bacaf5113094b2dc7e_Frame%20115.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	transition: all .3s linear;
}

.Sell .loginwrapper {
	background-color: var(--frost-black);
	
	/* height: 50vh; 
	*/
	

	padding-left: 30px;
	padding-right: 30px;
	padding-top: 10px;
	padding-bottom: 30px;

}

.Sell .centerwrapper {
	padding-top: 3rem;
	padding-bottom: 5rem;
	padding-left: 5rem;
	height: 75%;
	width: 35rem;

}

@media (max-width:650px) and (min-width: 500px) {
	.Sell .backimg {
		height: clamp(100%, 70vh, 70vh);
	}
	.Sell .centerwrapper {
		height: clamp(100%);
		width: clamp(50%, 30rem, 90%);
		padding-left: clamp(2vw, 5rem, 5rem);
	}
	
}

@media (max-width: 500px) {
	.Sell .backimg {
		height: clamp(100%, 70vh, 70vh);
	}
	.Sell .centerwrapper {
		padding-top: 2rem;
		padding-bottom: 2rem;
		padding-left: 1rem;
		padding-right: 1rem;
		height: 75%;
		width: 100%;
	}
	
}


.Sell .background-tint {
	background-color: rgba(0,0,0,0.3);
	background-blend-mode: overlay;
}

.Sell .logo {
	width: 10vw;
	
}
.Sell .center {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 50%;
  }


  .Sell .init {
	margin-top: 30px;
  }

  .Sell .imgwrapper {
	display: flex;
	justify-content: center;
  }
  .Sell .loginbtn {
	background-color: black;
	height: 50px;
	width: 100%;
	margin-top: 30px;;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
  }

  .Sell .loginbtn:hover {
	background-color: white;

  }
  .Sell .loginbtn:hover .text {
	color: black;	
  }
  .Sell .divider {
	width: 80%;
	margin: auto;
	margin-top: 10px;
	margin-bottom: 10px;
	background-color: gray;
	height: 1px;
	border: 1.5rem;
	
  }
  .errortext {
	color: red;
  }
  .sell .divider {
	width: 100%;
	height: 1px;
	background-color: grey;
	margin-top: 10px;
  }
  .centerButton {
	display: flex;
	align-items: center;
	justify-content: center;
  }