.pubs {
	width: 100%;
}

.pubs .wrapper {
	max-width: 1600px;
	margin: 0 auto;
	margin-top: 100px;
	margin-bottom: 100px;

	background-color: var(--frost-black);
	padding: 40px 20px 40px 20px;
	border-radius: 1rem;
}

.wrapper .pub {
	display: block;
	margin-top: 10px;
}

.pubs .heading {
	font-weight: 700;
    font-size: 56px;
    line-height: 105%;
	letter-spacing: -1px;
	font-family: Helvetica,Arial,sans-serif;
}

.pubs .subsubheading {
	font-weight: 700;
    font-size: 25px;
	line-height: 1.3em;
	letter-spacing: -.5px;
	font-family: Helvetica,Arial,sans-serif;
	color: white;
}

.pubs .subheading {
	font-weight: 700;
    font-size: 45px;
    line-height: 105%;
	letter-spacing: -1px;
	font-family: Helvetica,Arial,sans-serif;
}

.pubs .paraLarge {
	font-size: 1.25rem;
    line-height: 1.65;
	font-weight: 800;
	color: white;
	webkit-font-smoothing: antialiased;
}

.pubs .paraReg {
	font-size: 1rem;
    line-height: 1.5;
	color: white;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
}

.pubs .tag {
	background-color: rgb(56, 55, 55);
	padding: 5px;
	padding-left: 10px;
	padding-right: 10px;
	font-size: 1rem;
	font-weight: 600;
	color: var(--slate);
	font-style: monospace;
	text-transform: uppercase;
	display: inline-block;
	margin-right: 10px;
	opacity: 1;
}

.pubs .color1 {
	color: var(--purple) !important;
}

.pubs .color2 {
	color: var(--magenta) !important;
}

.pubs .imgwrapper {
	display: flex;
}

.pubs .paperimg {
	width: 100%;
	height: 100%;
	max-height: 250px;
	object-fit: cover;
	margin-bottom: 20px;
	margin-top: 20px;
	margin: 0 auto;
}

.pub:hover {
	cursor: pointer;
	background: rgba(45,45,45, 0.8);
	backdrop-filter: blur(5px);
	background-blend-mode: overlay;
	
}

.pub {
	padding: 10px;
}

@media(max-width: 767px) {
	.pubs .imgwrapper {
		margin-top: 20px;
	}
}