.shop .search {
	/* width: 90%; */
	margin: 0 auto;
	--headback: rgb(26, 26, 26);
	background-color: var(--headback);
	display: flex;
	justify-content: center;
}

.shop .search .searchbar {
	width: 90%;
	height: 50px;
	margin: 30px 10px 20px 0px;
	border: none;
	/* background-color: var(--headback); */
	color: black;
	font-size: 1.5rem;
	padding-left: 10px;
	border-radius: 0.5rem;
}

.shop. .hiddenMenu {

}
.shop .hiddenMenuButton {
	height: 50px;
	margin: 30px 15px 20px 10px;
}

.shop .filterwrapper {
	width: 90%;
	/* margin: 0 auto; */
	margin-left: 0px;
	/* display: flex; */
	/* justify-content: space-between; */
	background-color: var(--frost-black);
	height: calc(100% + 130px);
}

@media (max-width: 767px ) {
	.shop .filterwrapper {
		width: 100%;
		margin-right: 0px;
		height: calc(100%)
	}
	.shop .filterrow {
		margin-right: 10px;
	}
}

.shop .filterrow {
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 15px;
	padding-bottom: 15px;
}

.shop .filterrow:hover {
	background-color: var(--dark-gray);
	cursor: pointer;
}

.shop .dividerRw {
	width: 100%;
	height: 1px;
	background-color: grey;
	margin-top: 0px;
	margin-bottom: 0px;
}

.shop .righticon {
	color: white;
	float: right;
}

.shop .filtercircle {
	
	padding: auto;
	margin-left: 15px;
	margin-right: 5px;
	margin-top: 15px;
	
	color: white;
	background-color: dodgerblue;
	display: inline-block;
	font-size: 1.25rem;
	border-radius: 1.5rem;
}

.shop .viewdetailbutton {
	background-color: var(--frost-black);
	color: white;
	margin-top: 30px;
	padding: 30px;
	padding-top: .5rem;
	padding-bottom: .5rem;
	text-align: center;
	border-radius: 1.5rem;
	border: 1px solid white
}
.shop .viewdetailbutton:hover {
	background-color: white;
	color: black;
}


.shop .carcard {
	border-radius: 1.5rem;
	margin-top: 30px;
	transition: var(--transition)
}

.shop .carcard:hover {
	transform: translate(0, -10px);
	cursor: pointer;
}


.shop .innercarddetails {
	padding: 2rem;
	background-color: var(--frost-black);
	border-radius: 1.5rem;
}

.shop .timepick {
	display: flex;
	justify-content: center;
	padding: 20px;
	border-radius: 1.5rem;
}

.divgray {
	color: rgb(50, 50, 50);
}

.shop .topmargin {
	margin-bottom: 30px;
}
.shop .divider {
	width: 100%;
	height: 1px;
	background-color: grey;
	margin-top: 10px;
}
.check {
	color: green;
	margin-right: 25px;
}

.damageDiv {
	border-radius: 0.5rem;
	border: 1px solid #dedfe0;
}

.damageDivDivider {
	width: 100%;
	height: 1px;
	background-color: grey;
	margin-top: 10px;
}

.returnimage {
	width: 100%;
}

.shop .calcwrapper {
	background-color: rgb(255, 255, 255);
	/* background-color: var(--frost-black); */
	min-height: 100px;
	border-radius: 0.5rem;
	padding: 30px;
}

.shop .betweentext {
	width: 100%; 
	text-align: center; 
	border-bottom: 3px solid rgb(29, 91, 167); 
	line-height: 0.2em;
	margin: 10px 0 30px; 
	font-size: 3rem;
 } 
 
 .shop .betweentext .textcontent { 
    background:#fff; 
    padding:0 20px; 
	font-size: 2rem;
	margin-top: 10px;
}
.shop .solwrapper {
	background-color: black;
	/* background-color: var(--frost-black); */
	min-height: 100px;
	border-radius: 0.5rem;
	padding: 30px;
}